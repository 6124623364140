import React, { useState } from "react"

import CurrentLocationContext from "~context/currentLocation"

/* Import Global Hook(s) */
import { useWebsiteConfiguration } from "~queries/hooks"

const CurrentLocationProvider = ({ children }) => {

  const { activeLocation } = useWebsiteConfiguration()

  const [currentLocation, setCurrentLocation] = useState({activeLocation: activeLocation, hasBeenSet: false})

  return (    
    <CurrentLocationContext.Provider value={[currentLocation, setCurrentLocation]}>
      {children}
    </CurrentLocationContext.Provider>
  )
}

export default CurrentLocationProvider
